import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.css';

export default function AddTopic() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    
    const handleChange = (value) => {
        setContent(value);
    };
    
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ title, content });
    };
    
    return (
        <div className='add-container'>
        <h1>Add Topic</h1>
        <form onSubmit={handleSubmit}>
            <div className='form-group'>
            <label>Title</label>
            <input
                type='text'
                className='form-control'
                value={title}
                onChange={handleTitleChange}
            />
            </div>
            <div className='form-group'>
            <label>Content</label>
            <ReactQuill 
            className='content-editor'
            value={content} onChange={handleChange} />
            </div>
            <button type='submit' className='btn btn-primary'>
            Submit
            </button>
        </form>
        </div>
    );
    }