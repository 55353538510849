// react slick with h2

import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


const Sdata = [
    {
        title: "Title 1",
        desc: "Description 1",
        cover: "https://picsum.photos/200/300",
    },
    {
        title: "Title 2",
        desc: "Description 2",
        cover: "https://picsum.photos/200/300",
    },
    {
        title: "Title 3",
        desc: "Description 3",
        cover: "https://picsum.photos/200/300",
    },
]


const SlideCard = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        appendDots: (dots) => {
            return <ul style={{ margin: "0px" }}>{dots}</ul>
        },
    }
    return (
        <>
            <Slider {...settings}>
                {Sdata.map((value, index) => {
                    return (
                        <>
                            <div className='box d_flex top' key={index}>
                                <div className='left'>
                                    <h2>{value.title}</h2>
                                    <p>{value.desc}</p>
                                    <button className='btn-primary'>Visit Collections</button>
                                </div>
                                <div className='right'>
                                    <img src={value.cover} alt='' />
                                </div>
                            </div>
                        </>
                    )
                })}
            </Slider>
        </>
    )
}

export default SlideCard