import React, { useEffect, useState } from 'react';
import './Terms.css';

const PrivacyPolicy = () => {
    const [sections, setSections] = useState([]);

    useEffect(() => {
        let sectionCount = 0;

        function addSection(title, content) {
            sectionCount++;
            return { title: `${sectionCount}. ${title}`, content };
        }

        const newSections = [
            addSection('Introduction', 'Welcome to SolutionPUB. This privacy policy outlines how we collect, use, and protect your personal information when you use our website, located at <a href="https://solutionpub.dz">solutionpub.dz</a>.'),
            addSection('Information We Collect', 'We may collect various types of information including your name, email address, contact information, and usage data when you interact with our website.'),
            addSection('How We Use Your Information', 'The information we collect is used to provide and improve our services, personalize your experience, and communicate with you about updates and promotions.'),
            addSection('Data Protection', 'We take appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.'),
            addSection('Cookies', 'Our website uses cookies to enhance your browsing experience. By continuing to use our site, you consent to our use of cookies.'),
            addSection('Third-Party Services', 'We may employ third-party services for analytics, advertising, or payment processing. These services may have access to your personal information as necessary to perform their functions.'),
            addSection('Your Rights', 'You have the right to access, update, or delete your personal information at any time. Contact us if you wish to exercise these rights.'),
            addSection('Changes to This Policy', 'We may update this privacy policy from time to time. Any changes will be posted on this page, and we encourage you to review it regularly.'),
            addSection('Contact Us', 'If you have any questions or concerns about this privacy policy, please contact us at info@solutionpub.dz.'),
            // Add more sections as needed
        ];

        setSections(newSections);
    }, []);

    return (
        <div className="terms">
            <h2>Privacy Policy</h2>
            {sections.map((section, index) => (
                <div key={index}>
                    <h3 dangerouslySetInnerHTML={{ __html: section.title }}></h3>
                    <p dangerouslySetInnerHTML={{ __html: section.content }}></p>
                </div>
            ))}
        </div>
    );
};

export default PrivacyPolicy;
