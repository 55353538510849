import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Navigate, Routes, Route } from "react-router-dom";
import Header from "./common/header/Header";
import Pages from "./pages/Pages";
import Data from "./components/Data";
import Cart from "./common/Cart/Cart";
import Footer from "./common/footer/Footer";
import Sdata from "./components/shops/Sdata";
import Login from "./login/Login";
import Auth from "./login/Auth";
import Confirm from "./login/Confirm";
import Register from "./login/Register";
import Test from "./login/test";
import Tes from "./login/tes";
import Product from "./common/Product/Product";
import Shop from "./common/Shop/Shop";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import Forum from "./forum/Main";
import Blog from "./blog/Main"
import AddTopic from "./forum/Add";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/Privacy";
import './i18n';

function App() {
  const { productItems } = Data;
  const { shopItems } = Sdata;
  const [CartItem, setCartItem] = useState([]);

  useEffect(() => {
/*
    sessionStorage.removeItem('cart');
    setCartItem([]);
    return
  */
    const cartStorage = sessionStorage.getItem('cart');
    if (cartStorage) {
      setCartItem(JSON.parse(cartStorage));
    }
  }, []);

  const updateCartStorage = (cart) => {
    sessionStorage.setItem('cart', JSON.stringify(cart));
    setCartItem(cart);
  };

  const getUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const addToCart = (product, qt = 1) => {
    if (qt == 0) {
      return;
    }
    console.log("product", product);
    let updatedCart = [...CartItem];
    console.log("jkko", product)
    if (product.type === "service") {
      console.log("product", product);
      const serviceQty = updatedCart.filter((item) => item.id === product.service_id).map(item => item.qty)[0];
      const serviceId = updatedCart.filter((item) => item.id === product.service_id).map(item => item.id)[0];


      console.log("serviceId", serviceId);
      if (serviceQty > 0) {
        
        // get in updatedCart the product with the same service_id, and add the qty
        updatedCart = updatedCart.map((item) => {
          if (item.id === serviceId) {
            item.qty += 1;
          }
          return item;
        }

        );



      } else {
        updatedCart.push({ id: getUniqueId(), type: "service", qty: 1, service_id: product.serviceId, images: product.zonesImages, color: product.color });

      }
    } else {
      const productIndex = updatedCart.findIndex((item) => item.id === product.id);
      if (productIndex > -1) {
        updatedCart[productIndex].qty += qt;
      } else {
        updatedCart.push({ id: product.id, type: "product", qty: qt });
      }
    }

    
    updateCartStorage(updatedCart);
  };

  const removeFromCart = (product) => {
    let updatedCart = [...CartItem];

    if (product.type === "service") {
      const serviceId = updatedCart.filter((item) => item.id === product.service_id).map(item => item.id)[0];
      updatedCart = updatedCart.filter((item) => item.id !== serviceId);
    } else {
      updatedCart = updatedCart.filter((item) => item.id !== product.id);
    }

    updateCartStorage(updatedCart);
  };

  const decreaseQty = (product) => {
    let updatedCart = [...CartItem];

    if (product.type === "service") {
      // use serviceid not index
      const serviceId = updatedCart.filter((item) => item.id === product.service_id).map(item => item.id)[0];
      const serviceQty = updatedCart.filter((item) => item.id === product.service_id).map(item => item.qty)[0];

      if (serviceQty === 1) {
        // remove
        updatedCart = updatedCart.filter((item) => item.id !== serviceId);
      } else {
        updatedCart = updatedCart.map((item) => {
          if (item.id === serviceId) {
            item.qty -= 1;
          }
          return item;
        });
      }


    } else {
      const productIndex = updatedCart.findIndex((item) => item.id === product.id);
      if (productIndex > -1) {
        if (updatedCart[productIndex].qty === 1) {
          updatedCart = updatedCart.filter((item) => item.id !== product.id);
        } else {
          updatedCart[productIndex].qty -= 1;
        }
      }
    }

    updateCartStorage(updatedCart);
  };

  const renderRouteWithHeaderAndFooter = (Component, props) => (
    <>
      <Header CartItem={CartItem} />
      <Component {...props} />
      <Footer />
    </>
  );

  

  return (
    <Router>
      <ToastContainer />
      <Routes>
        {sessionStorage.getItem('notconfirmed') === 'true' ? (
          <>
            <Route path='/confirm' element={<Confirm />} />
            <Route path='*' element={<Navigate to='/confirm' />} />
          </>
        ) : (
          <>
            <Route path='/auth/:authToken/:username/:avatar' element={<Auth />} />
            <Route path='/register/:token' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/customize/:id' element={<Test  addToCart={addToCart} />} />
            <Route path='/tes' element={<Tes />} />
            <Route path='/product/:id' element={renderRouteWithHeaderAndFooter(Product, { productItems, addToCart })} />
            <Route path='/shop' element={renderRouteWithHeaderAndFooter(Shop, { productItems, addToCart })} />
            <Route path='/' element={renderRouteWithHeaderAndFooter(Pages, { productItems, addToCart, shopItems })} />
            <Route path='/cart' element={renderRouteWithHeaderAndFooter(Cart, { CartItem, addToCart, decreaseQty, removeFromCart })} />
            <Route path='/blog' element={renderRouteWithHeaderAndFooter(Blog, { productItems, addToCart })} />
            <Route path='/forum' element={renderRouteWithHeaderAndFooter(Forum, { productItems, addToCart })} />
            <Route path='/forum/post' element={renderRouteWithHeaderAndFooter(AddTopic, { productItems, addToCart })} />
            <Route path='/terms-and-conditions' element={renderRouteWithHeaderAndFooter(Terms, { productItems, addToCart })} />
            <Route path='/privacy-policy' element={renderRouteWithHeaderAndFooter(PrivacyPolicy, { productItems, addToCart })} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
