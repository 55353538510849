import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import Konva from 'konva';
import Perspective from './perspective';
import BackgroundImage from './T.png';

function Tshirt(props) {
  const { zones } = props;
  const initialPoints = [
    [285, 209],
    [533, 210],
    [532, 535],
    [283, 538]
  ];
  const shirtRef = useRef();

  const [images, setImage] = useState(new window.Image());
  const normalizeToPixels = (coordinates) => {
    return coordinates.map(coord => [
        Math.round(coord.x * 800),
        Math.round(coord.y * 800),
    ]);
};

const getImg = async (url) => {
    // fetch api.solutionpub.dz/api/image post 
    if (!props.mockup) {
      return BackgroundImage;
    }
    const response = await fetch('https://api.solutionpub.dz/api/image', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            url,
        }),
    });

    const data = await response.json();
    return data.data.url;
    
};



  useEffect(() => {
    const loadImage = async (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    };
  
    const loadImageAndSetImage = async () => {
      const MyImage = new window.Image();
      MyImage.src = await getImg("https://api.solutionpub.dz/public/mockup/" + props.mockup);
      MyImage.onload = () => {
        setImage(MyImage);
      };
    };
  
    loadImageAndSetImage();
  }, [props.mockup]);

  useEffect(() => {
    const loadImage = async (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = src;
          // add style to img : max-height : 40px
        });
      };

      const loadPerspective = async (canvas, perspectiveImage, points) => {
        const ctx = canvas.getContext('2d');

    const npoints = normalizeToPixels(points);
    // Make a copy of initialPoints to avoid mutation
    const updatedPoints = npoints.map(point => [point[0], point[1]]);

    // Load the perspective image with crossOrigin attribute set
    const perspective1 = new Image();
    perspective1.crossOrigin = 'anonymous';
    perspective1.src = perspectiveImage;

    // Wait for the perspective image to load
    await new Promise((resolve, reject) => {
        perspective1.onload = resolve;
        perspective1.onerror = reject;
    });

    // Calculate the dimensions for the canvas to fit both image and white space
    const canvasRatio = canvas.width / canvas.height;
    const imageRatio = perspective1.width / perspective1.height;


    console.log('im', updatedPoints)

        // Calculer la largeur et la hauteur du rectangle
        const xCoords = updatedPoints.map(point => point[0]);
        const yCoords = updatedPoints.map(point => point[1]);
    
        const minX = Math.min(...xCoords);
        const maxX = Math.max(...xCoords);
        const minY = Math.min(...yCoords);
        const maxY = Math.max(...yCoords);
    
        const width = maxX - minX;
        const height = maxY - minY;
    
        console.log('Width:', width);
        console.log('Height:', height);
        console.log('ratio:', width / height);
        console.log('imgRatio:', perspective1.width / perspective1.height);

      


    // Create a new canvas for the transformed image
    const canvas2 = document.createElement('canvas');
    canvas2.width = canvas.width;
    canvas2.height = canvas.height;
    const ctx2 = canvas2.getContext('2d');

    // Calculer les dimensions de l'image redimensionnée
    let newWidth, newHeight;
    if (width / height > imageRatio) {
      newHeight = height;
      newWidth = newHeight * imageRatio;
    } else {
      newWidth = width;
      newHeight = newWidth / imageRatio;
    }

    console.log('New Width:', newWidth);
    console.log('New Height:', newHeight);

    // Calculer les offsets pour centrer l'image redimensionnée dans le rectangle
    const offsetX = minX + (width - newWidth) / 2;
    const offsetY = minY + (height - newHeight) / 2;

    // Dessiner l'image redimensionnée sur le canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(perspective1, offsetX, offsetY, newWidth, newHeight);

    // Dessiner les points mis à jour

    // Convert the second canvas to data URL
    const resizedImageDataUrl = canvas2.toDataURL();

    // Create a new Image object for the transformed image
    const perspective = new Image();
    perspective.crossOrigin = 'anonymous';
    perspective.src = resizedImageDataUrl;

    // Wait for the perspective image to load
    await new Promise((resolve, reject) => {
        perspective.onload = resolve;
        perspective.onerror = reject;
    });

    // Initialize the Perspective object and draw the transformed image
    const p = new Perspective(ctx, perspective);
    p.draw(updatedPoints);
};

    // Remove existing canvases if any
    const canvasContainer = document.getElementById('canvas-container');
    canvasContainer.innerHTML = '';

    // Create canvases for each zone
    Object.keys(zones).forEach((zoneKey) => {
      const canvas = document.createElement('canvas');
      canvas.width = 800;
      canvas.height = 800;
      canvas.style.position = 'absolute';
      canvas.style.zIndex = 1;
      canvasContainer.appendChild(canvas);

      loadPerspective(canvas, zones[zoneKey].perspectiveImage, zones[zoneKey].dimensions);
    });
  }, [zones]);





  useEffect(() => {
    const updateShirtColor = () => {
      if (shirtRef.current) {
        shirtRef.current.cache();
        shirtRef.current.getLayer().batchDraw();
        shirtRef.current.filters([Konva.Filters.RGB]);
        shirtRef.current.blue(props.color.b);
        shirtRef.current.red(props.color.r);
        shirtRef.current.green(props.color.g);
      }
    };

    if (images.complete) {
      updateShirtColor();
    } else {
      images.onload = updateShirtColor;
    }

    return () => {
      images.onload = null;
    };
  }, [props.color, images]);

  return (
    <div style={{ position: 'relative', width: '800px', height: '800px' }}>
      <Stage
        style={{  position: 'absolute', top: 0, left: 0, zIndex: 1 }}
        width={800}
        height={800}
      >
        <Layer>
          <KonvaImage
            filters={[Konva.Filters.RGB]}
            image={images}
            ref={shirtRef}
            x={0}
            y={0}
            width={800}
            height={800}
          />
        </Layer>
      </Stage>

      <div id="canvas-container" style={{ position: 'relative', width: '800px', height: '800px' }}>
        {/* Container for dynamically created canvases */}
      </div>
    </div>
  );
}

export default Tshirt;
