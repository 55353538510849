import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../api/logout";
import { categories } from "../../api/categories";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useTranslation } from "react-i18next";
const Search = ({ CartItem }) => {
  const { t } = useTranslation()

  const [options, setOptions] = useState([{ value: 'all', label: t('navbar.allCategories') }]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({ value: 'all', label: t('navbar.allCategories') });
  const navigate = useNavigate();
  const [logoutLoading, setLogoutLoading] = useState(false);


  // check if route is "forum"
  const location = useLocation();
  const isForum = location.pathname === "/forum";


  useEffect(() => {
    const handleScroll = () => {
      const search = document.querySelector(".search");
      if (search) {
        search.classList.toggle("active", window.scrollY > 100);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await categories();
        const categoriesOptions = response.data.map((category) => ({
          value: category.id,
          label: category.name,
        }));
        setOptions((prevOptions) => [...prevOptions, ...categoriesOptions]);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = () => {
    if (isForum) {
      navigate(`/forum?q=${searchQuery}`);

    } else {
      navigate(`/shop?q=${searchQuery}&cat=${selectedCategory.value}`);

    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const signout = async () => {
    if (logoutLoading) return;
    try {
      setLogoutLoading(true);
      const result = await logout(sessionStorage.getItem("authToken"));

      if (result) {
        sessionStorage.removeItem("authToken");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("avatar");
        toast.success('Logout successful!', { position: "bottom-center" });
        navigate("/");
      }
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error('Logout failed!', { position: "bottom-center" });
    } finally {
      setLogoutLoading(false);
    }
  };

  return (
    <section className="search">
      <div className="container c_flex">
        <div className="logo width">
          <img src="/images/logo.svg" alt="logo" />
        </div>

        <div className="search-box f_flex">
          <i className="fa fa-search" onClick={handleSearch}></i>
          <input
            type="text"
            
            placeholder= {isForum ? t('navbar.search2') : t('navbar.search')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}

            onKeyPress={handleKeyPress}

          />
          {isForum ? null : (
          <span>
            <Select
              className="search-cat"
              value={selectedCategory}
              onChange={setSelectedCategory}
              options={options}
            />
          </span>
          )}
        </div>

        <div className="icon f_flex width">
          <div className="login">
            {sessionStorage.getItem("authToken") ? (
              <div className="icon-circle">
                {sessionStorage.getItem("avatar") && sessionStorage.getItem("avatar") != "null" && sessionStorage.getItem("avatar") != "undefined" ? (
                  <img
                    src={sessionStorage.getItem("avatar")}
                    className="avatar"
                    alt="avatar"
                  />
                ) : (
                  <img
                    className="avatar"
                    src="/images/avatar.png"
                    alt="avatar"
                  />
                )}

               
              </div>
            ) : (
              <Link to="/login">
                <i className="fa fa-user icon-circle"></i>
              </Link>
            )}
          </div>

          <div className="cart">
            <Link to="/cart">
              <i className="fa fa-shopping-bag icon-circle"></i>
              {CartItem.length === 0 ? "" : <span>{CartItem.length}</span>}
            </Link>
          </div>

          {sessionStorage.getItem("authToken") && (
            <div>
              <i
                style={{
                  cursor: logoutLoading ? "not-allowed" : "pointer",
                }}
                onClick={signout}
                className="fa fa-sign-out icon-circle"
              ></i>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Search;
