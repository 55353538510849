import axios from "axios"

export const products = async (search, category, page) => {
  const response = await axios.post("https://api.solutionpub.dz/api/products", {
    search,
    category,
    page
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error(response)
  }
}

