const Sdata = [
  {
    id: 1,
    title: "Download our app and get 50% off",
    desc: "We also have a mobile app for you to download and get 50% off on your first purchase.",
    title_fr: "Téléchargez notre application et obtenez 50% de réduction",
    desc_fr: "Nous avons également une application mobile à télécharger et obtenez 50% de réduction sur votre premier achat.",
    title_ar: "قم بتنزيل تطبيقنا واحصل على خصم 50٪",
    desc_ar: "لدينا أيضًا تطبيق محمول يمكنك تنزيله والحصول على خصم 50٪ على أول عملية شراء.",
    cover: "./images/SlideCard/slide-1.png",
    btn: "Download now",
    btn_fr: "Télécharger maintenant",
    btn_ar: "قم بالتحميل الآن",
  },
  {
    id: 2,
    title: "Customize our special stickers",
    desc: "Did you know that you can customize our special stickers? You can choose the size, color, and even the text.",
    title_fr: "Personnalisez nos autocollants spéciaux",
    desc_fr: "Saviez-vous que vous pouvez personnaliser nos autocollants spéciaux? Vous pouvez choisir la taille, la couleur et même le texte.",
    title_ar: "قم بتخصيص ملصقاتنا الخاصة",
    desc_ar: "هل تعلم أنه يمكنك تخصيص ملصقاتنا الخاصة؟ يمكنك اختيار الحجم واللون وحتى النص.",
    cover: "./images/SlideCard/slide-2.gif",
    btn: "Customize now",
    btn_fr: "Personnaliser maintenant",
    btn_ar: "قم بالتخصيص الآن",
  },
  {
    id: 3,
    title: "Our customization app is now available",
    desc: "We have a new app that allows you to customize our products. You can choose the size, color, and even the text.",
    title_fr: "Notre application de personnalisation est maintenant disponible",
    desc_fr: "Nous avons une nouvelle application qui vous permet de personnaliser nos produits. Vous pouvez choisir la taille, la couleur et même le texte.",
    title_ar: "تطبيقنا للتخصيص متاح الآن",
    desc_ar: "لدينا تطبيق جديد يتيح لك تخصيص منتجاتنا. يمكنك اختيار الحجم واللون وحتى النص.",
    cover: "./images/SlideCard/slide-3.png",
    btn: "Try now",
    btn_fr: "Essayer maintenant",
    btn_ar: "جرب الآن",
  }
]
export default Sdata
