import React from 'react';
import "./style.css";
function Main() {
    return (
        <div className="forum">
            <div className="cats">
                <h2>
                    Categories
                </h2>
                <div className="cats-list">
                    <div className="cat">
                        Technology
                        </div>
                    <div className="cat">
                        Science
                        </div>
                    <div className="cat">
                        Health
                        </div>
                    <div className="cat">
                        Business
                        </div>

                </div>

                </div>

            <div className="topics">
            { /*
                <div className="topic-search">
                    <input type="text" placeholder="Search" />
                    <button><i className="fas fa-search"></i></button>
                </div>
                */ }
                <div className="topics-grid">
            <div className="topic">
              <div className="topic-user">
                <img src="https://via.placeholder.com/150" alt="User" />
                <span>Username</span>
                </div>
                <div className="topic-content">
                    <h3>Topic Title</h3>
                    <p>Topic Content</p>
                 <div className="actions">
                    <i className="fas fa-heart"></i>
                    <i className="fas fa-comment"></i>
                </div>
                </div>

            </div>
            <div className="topic">
              <div className="topic-user">
                <img src="https://via.placeholder.com/150" alt="User" />
                <span>Username</span>
                </div>
                <div className="topic-content">
                    <h3>Topic Title</h3>
                    <p>Topic Content</p>
                 <div className="actions">
                    <i className="fas fa-heart"></i>
                    <i className="fas fa-comment"></i>
                </div>
                </div>
                </div>

                <div className="topic">
              <div className="topic-user">
                <img src="https://via.placeholder.com/150" alt="User" />
                <span>Username</span>
                </div>
                <div className="topic-content">
                    <h3>Topic Title</h3>
                    <p>Topic Content</p>
                 <div className="actions">
                    <i className="fas fa-heart"></i>
                    <i className="fas fa-comment"></i>
                </div>
                </div>
                </div>


</div>
                </div>
            
        </div>
    );
}

export default Main;