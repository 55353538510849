//Custmize.js file
import React, { useEffect, useState } from 'react';
import { CirclePicker } from 'react-color';
import Tshirt from './Tshirt';
import axios from 'axios';
// import test.css
// use params
import { useParams } from 'react-router-dom';
import './test.css';
import { ReactPhotoEditor } from 'react-photo-editor';
import './editor.css';
import FadeInOut from "./FadeInOut";

import { toast } from 'react-toastify';

const Customize = ({addToCart}) => {


    const serviceId = useParams().id;
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [clickedZone, setClickedZone] = useState(null);

    const [file, setFile] = useState();
    const [showModal, setShowModal] = useState(false);
  
    // Show modal if file is selected
    const showModalHandler = () => {
      if (file) {
        setShowModal(true);
      }
    };
  
    // Hide modal
    const hideModal = () => {
      setShowModal(false);
    };
    const handleSaveImage = (editedFile) => {
        setFile(editedFile);
        console.log("ef", editedFile);
        setShowModal(false);
      };
    // ajoute l'objet zones

    /*
    "zone1 : { (0.17, 0.17), (0.50, 0.17), (0.51, 0.51), (0.14, 0.46) }"
"zone2 : { (0.22, 0.68), (0.48, 0.60), (0.52, 0.80), (0.10, 1.00) }"
*/

// use effect to call api axios api.solutionpub.dz/api/getService with id
useEffect(() => {
    const fetchData = async () => {
    const result = await axios.get('https://api.solutionpub.dz/api/service/' + serviceId);
    if (result && result.data.success) {
    console.log(result.data.service);
    const transformedZones = {};
    setMockup(result.data.service.mockup);
    result.data.service.zones.forEach((zoneCoords, index) => {
      transformedZones[`zone${index + 1}`] = {
        file: null,
        filename: null,
        perspectiveImage: 'https://i.ibb.co/DfNcLVS/sp3.png', // URL de votre image de perspective
        dimensions: zoneCoords.map(point => ({
          x: point.x, // Exemple de transformation en pourcentage par rapport à la largeur de l'image de fond
          y: point.y, // Exemple de transformation en pourcentage par rapport à la hauteur de l'image de fond
        })),
      };
    });

    console.log(transformedZones);
    setTextLayerColors(result.data.service.colors);
    setMinUnits(result.data.service.min_units);
    

    console.log(zones);
      
 setZones(transformedZones);
    setTimeout(() => {
    setLoading(false);
    
    }, 2000);

    }


    };
    fetchData();
    }, []);


    const [mockup, setMockup] = useState('');
    const [minUnits, setMinUnits] = useState(1);
    const [qty, setQty] = useState(1);

    const [zones, setZones] = useState({
        zone1: {
            perspectiveImage: 'https://i.ibb.co/DfNcLVS/sp3.png',
            dimensions: [
                { x: 0.36, y: 0.26 },
                { x: 0.66, y: 0.26 },
                { x: 0.66, y: 0.67 },
                { x: 0.35, y: 0.67 },
            ],
        },

    });


    function hexToRgba(hex) {
        const hexCode = hex.replace('#', '');
        const r = parseInt(hexCode.substring(0, 2), 16);
        const g = parseInt(hexCode.substring(2, 4), 16);
        const b = parseInt(hexCode.substring(4, 6), 16);

        return { r, g, b };

    }

    function rgbaToHex({ r, g, b}) {
        const toHex = (value) => {
          const hex = value.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        };
      
        return (
          '#' +
          toHex(r) +
          toHex(g) +
          toHex(b)
        );
      }

    const saveImage = async () => {
        // if there is no file in every zone, return
        for (const zoneKey in zones) {
            if (!zones[zoneKey].file) {
                toast.error('Please upload an image for every area');
                return;
            }

        }

        // if there is no color, return
        if (!color) {
            toast.error('Please choose a color');
            return;
        }


        try {
            setBtnLoading(true);
            for (const zoneKey in zones) {
                const zone = zones[zoneKey];
                const formData = new FormData();
                formData.append('file', zone.file);
                const response = await axios.post('https://api.solutionpub.dz/api/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                zones[zoneKey].filename = response.data.filename;
                console.log("fl", response.data.filename);
            }

            const zonesImages = Object.keys(zones).map((zoneKey) => ({
                image: zones[zoneKey].filename,
            }));

            console.log("zi", zonesImages);

            const data = {
                type: "service",
                color: rgbaToHex(color),
                serviceId: serviceId,
                zonesImages: zonesImages,
                qty: 1,
            };

            

            await addToCart(data);

            toast.success('Your customization was added to cart');

        } catch (error) {
            console.error(error);
            toast.error('An error occurred');
        } finally {
            setBtnLoading(false);

        }
    };
const changeImage = (zoneKey, e, t) => {
    // if there is no file, return
    
    let file;
    if (t === 1) {
    file = e.target.files[0];
    } else {
    file = e;
    }
    if (!file) {
    return;
    }
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
    setZones((prevZones) => ({
    ...prevZones,
    [zoneKey]: {
    ...prevZones[zoneKey],
    file: file,
    perspectiveImage: reader.result,
    },
    }));
    };
    reader.readAsDataURL(file);
    }


const [textLayerColors, setTextLayerColors] = useState([
'#ffffff'
]);
const [color, setColor] = useState(hexToRgba(textLayerColors[0]));

useEffect(() => {
    console.log(hexToRgba(textLayerColors[0]));
    setColor(hexToRgba(textLayerColors[0]));
    }, [textLayerColors]);


    


return (
<>
<FadeInOut show={loading} 

duration={loading ? 0 : 2000}
   style={{
    position: "fixed",
    width: "100%",
    backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100vh",
  fontSize: "20px",
  zIndex: "5000",
}}
>


<div
className={loading ? "loading" : "loaded"}

         
            
            >
      
                  <img  
                  src="/images/logo.svg"
                  alt="logo"
                  style={{ width: "600px", height: "100px" }}
                  />
              </div>
</FadeInOut>

<aside
id="app-sidebar">
 <div className="color-picker">
 <i className="
        app-title-icon
        fas fa-palette"></i>

    <h3 
    className="app-title"
    >
        Choose color</h3>
    
         <CirclePicker
         id="circle-picker"
         display="flex"
         circleSize={35}
         colors={textLayerColors}
         color={color}
         onChange={(color) => {
         setColor(color.rgb);
         console.log(color.rgb);
         }}
         />
         </div>
         <div 
            className="image-picker"

         style={{ height: '500px'}}
         >
        <i className="
        app-title-icon
        fas fa-image"></i>

<h3 
    className="app-title"
    >
        Choose your images</h3>
{Object.keys(zones).map((zoneKey) => (
  <div key={zoneKey}>
    <div className="file-upload">

              <h3> Click box to upload</h3>
              <p>Maximun file size 10mb</p>
    <input 
    className="file-input"
      onChange={(e) => changeImage(zoneKey, e, 1)}
      type="file"
      multiple={false}
    />

     {zones[zoneKey].file && (
      <>
        <button
          style={{ position: 'absolute', top: '-10px', right: '-10px', backgroundColor: 'white', border: 'none',
            width: '30px', height: '30px',
            borderRadius: '50%', padding: '5px', cursor: 'pointer' }}

          onClick={() => setClickedZone(zoneKey)}
        >
         <i 
         style={{ fontSize: '20px'

         }}
         className="fas fa-edit"></i>
        </button>
        <ReactPhotoEditor
        className="editor"
          open={clickedZone === zoneKey}
          onClose={() => setClickedZone(null)}
          file={zones[zoneKey].file}
          onSaveImage={(editedFile) => changeImage(zoneKey, editedFile, 2)}
        />
      </>
    )}
    </div>


   
  </div>
))}
    <i className="
        app-title-icon
        fas fa-tshirt"></i>

    <h3 
    className="app-title"
    >
        Choose quantity</h3>





<div
className="numberstyle-qty-container"
>
<label
style={{
    fontSize: "12px",
    color: "grey",
    marginTop: "10px",
    textAlign: "center",
    width: "100%",
}}
>Minimum quantity: {minUnits}</label>
<div className="numberstyle-qty"><div
onClick={() => {
    if (qty > minUnits) {
        setQty(qty - 1);
    }

}
}
className="qty-btn qty-rem">-</div>
        <input className="numberstyle" 
        type="number" 
        value={qty}
        min={minUnits}
        max="99999"
        onChange={(e) => setQty(parseInt(e.target.value))}
        />

        <div 
        onClick={() => {
            setQty(qty + 1);

        }
    }
        className="qty-btn qty-add">+</div>
        </div>
        
</div>



  

    </div>

    <button
    className="add-to-cart"
    disabled={btnLoading}
    onClick={saveImage}>
        {btnLoading ? "Loading..." : "Add to cart"}
        </button> 
</aside>

<div id="app-content"
style={
    {
        display: {loading} ? "flex" : "none",
    }

}
>
<Tshirt
        mockup={mockup}
      zones={zones}
      color={color} 

      
      
      />
</div>

    </>
    );
    
    };
    export default Customize;