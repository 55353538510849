import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import ReactPaginate from 'react-paginate';
import { useTranslation } from "react-i18next";

import lot from "../Animation.json";

const ShopCart = ({ shopItems, totalPages, addToCart }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const increment = () => {
    setCount(count + 1);
  };

  return (
    <>
      {shopItems.length === 0 ? (
        <Lottie 
          animationData={lot}
          style={{ width: 200, height: 200 }}
        />
      ) : (
        shopItems.map((productItems, index) => {
          if (productItems.type === 'product') {
            return (
              <div className='box' key={index}>
                <div className='product mtop'>
                  <div 
                    onClick={() => navigate(`/product/${productItems.id}`)}
                    className='img'
                  >
                    {productItems.promo > 0 && <span className='discount'>{productItems.promo}% Off</span>}
                    <img src={`https://api.solutionpub.dz/public/products/${productItems.picture}`} alt='' />
                    <div className='product-like'>
                      <label>{count}</label> <br />
                      <i className='fa-regular fa-heart' onClick={increment}></i>
                    </div>
                  </div>
                  <div className='product-details'>
                    <h3>{productItems.name}</h3>
                    <div className='rate'>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                    </div>
                    <div className='price'>
                      {productItems.promo > 0 ? (
                        <h4>
                          <span className='discounted-price'>{productItems.price} {t('currency')}</span>
                          {(productItems.price - (productItems.price * productItems.promo) / 100).toFixed(2)} {t('currency')}
                        </h4>
                      ) : (
                        <h4>{productItems.price} {t('currency')}</h4>
                      )}
                      <button onClick={() => addToCart(productItems)}>
                        <i className='fa fa-plus'></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else if (productItems.type === 'service') {
            return (
              <div className='box srv' key={index}>
                <div className='service mtop'>
                  <div className='service-img'>
                    {productItems.promo > 0 && <span className='discount'>{productItems.promo}% Off</span>}
                    <img src={`https://api.solutionpub.dz/public/services/${productItems.picture}`} alt='' />
                    <div className='product-like'>
                      <label>{count}</label> <br />
                      <i className='fa-regular fa-heart' onClick={increment}></i>
                    </div>
                  </div>
                  <div className='product-details2'>
                    <h3>{productItems.name}</h3>
                    <div className='price'>
                      {productItems.promo > 0 ? (
                        <h4 className={t('direction') === 'rtl' ? 'rtl' : ''}>
                          <span className='discounted-price'>{productItems.price} {t('currency')}</span>
                          {(productItems.price - (productItems.price * productItems.promo) / 100).toFixed(2)} {t('currency')}
                        </h4>
                      ) : (
                        <h4 className={t('direction') === 'rtl' ? 'rtl' : ''}>{productItems.price} {t('currency')}</h4>
                      )}
                      <div className='customize-btn' onClick={() => navigate(`/customize/${productItems.id}`)}>
                        {t('home.customize_now')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null; // fallback for unexpected cases
        })
      )}
      {totalPages > 1 && (
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={totalPages}
          onPageChange={({ selected }) => {
            console.log(selected);
          }}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      )}
    </>
  );
};

export default ShopCart;
