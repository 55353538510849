import React, { useState } from "react";
import VerificationInput from "react-verification-input";

import { logout } from "../api/logout"

import { toast } from 'react-toastify';
// use history
import { useNavigate } from "react-router-dom"

import "./login.css";

import { confirm } from "../api/confirm";

const Form = () => {
  const [value, setValue] = useState("");
    const history = useNavigate()
    const [logoutLoading, setLogoutLoading] = useState(false);
    const [loading, setLoading] = useState(false)


  const handleSubmit = async () => {
    console.log(value);
    setLoading(true)
    const authToken = sessionStorage.getItem("authToken")
    try {
        const result = await confirm(authToken, value)
        console.log(result)
        if (result && result.success) {
            toast.success('Welcome');
            // delete session.storage not confirmed
            sessionStorage.removeItem("notconfirmed")
            window.location.href = "/"
        }
        }
        catch (error) {
            console.error("Login failed:", error)
            toast.error("Invalid code", { position: "bottom-center" });
        }
        finally {
            setLoading(false)
        }


  }


    const signout = async () => {
      if (logoutLoading) return; // prevent click if logout is loading
      try {
        setLogoutLoading(true)
        const result = await logout(sessionStorage.getItem("authToken"))
  
        if (result) {
          // remove authtoken from session
          sessionStorage.removeItem("authToken")
          sessionStorage.removeItem("username")
          sessionStorage.removeItem("avatar")
          sessionStorage.removeItem("notconfirmed")
          toast.success('Logout successful!', { position: "bottom-center" });
          window.location.href = "/"
        }
        // Perform any additional actions after successful logout
      } catch (error) {
        // Handle any errors that occur during logout
        console.error("Logout failed:", error)
        toast.error('Logout failed!', { position: "bottom-center" });
      } finally {
        setLogoutLoading(false)
      }
    }

  return (
    <div
     id = "confirm-container">
            <div id='logout-confirm'>
          <i
                  style={{
                    cursor: logoutLoading ? "not-allowed" : "pointer",
                  }}
                  onClick={signout}
                  className='fa fa-sign-out'
                ></i>
          </div>


             <div className='logo-login'>
            <img src={"./images/logo.svg"} alt='' />
          </div>


      
        <div id = "confirm">
    <VerificationInput
      autoFocus={true}
      value={value}
      length={6}
      onChange={(val) => setValue(val)}
      onSubmit={(val) => console.log(val)}
      validChars="0-9"
      classNames={{
        container: "confcontainer",
        character: "character",
        characterInactive: "character--inactive",
        characterSelected: "character--selected",
        characterFilled: "character--filled",
      }}
     
    />
    <button 
    
    disabled={loading}
    
    className="b"
    onClick={() => handleSubmit()}
    >
      {loading ? "Loading..." : "Submit"}
    </button>

</div>
</div>
  );
};

export default Form;
