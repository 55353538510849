import React from "react"
import { useTranslation } from "react-i18next"

const Head = () => {
  const { t, i18n } = useTranslation()

  const languages = ["en", "fr", "ar"]
  const changeLanguage = () => {
    const currentLanguage = i18n.language
    let nextLanguage = languages.indexOf(currentLanguage) + 1
    nextLanguage = nextLanguage === languages.length ? 0 : nextLanguage
    i18n.changeLanguage(languages[nextLanguage])
    // save the language in local storage
    localStorage.setItem("lang", languages[nextLanguage])
  }


  return (
    <>
      <section className='head'>
        <div className='container d_flex'>
          <div className='left row'>
            <div>
            <i className='fa fa-phone'></i>
            <label> +213 0560 99 14 52</label>
            </div>
            <div>
            <i className='fa fa-envelope'></i>
            <label> contact@solutionpub.dz</label>
              </div>


          </div>
          <div className='right row RText'>
            <label
              style={{ cursor: "pointer", textTransform: "uppercase" }}
              onClick={() => changeLanguage()}

            >
              {i18n.language}
            </label>

          </div>
        </div>
      </section>
    </>
  )
}

export default Head
