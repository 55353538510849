import React from "react"

// use effect and state
import { useEffect, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { categories } from "../../api/categories"
import Skeleton from 'react-loading-skeleton'
// navigate
import { Link } from "react-router-dom"

import 'react-loading-skeleton/dist/skeleton.css'
// get actual lang from i18n
import { useTranslation } from "react-i18next"


const TopCart = () => {
  const [Tdata, setTdata] = useState([])
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  useEffect(() => {
    const fetchCategories = async () => {
      const result = await categories()
      if (result && result.data) {
        setTdata(result.data)
      }
    }
    fetchCategories()
  }
  , [])



  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
     {Tdata.length > 0 ? (
      <Slider {...settings}>
        {Tdata.map((value, index) => {
          return (
            <>
              <div className='box product' key={index}>
                <div className='nametop d_flex'>
                  <span className='tleft'>
                    {lang === 'en' ? value.name : lang === 'fr' ? value.name_fr : value.name_ar}
                    
                    </span>
                </div>
                <Link to={`/shop?cat=${value.id}`}>
                <div className='img'>
                  <img src={`https://api.solutionpub.dz/public/categories/${value.picture}`} alt='' />
                </div>
                </Link>
              </div>
            </>
          )
        })}
      </Slider>
   ) : (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Skeleton 
        className="skelton"
        count={1
        } 
        containerClassName="flex-1" 
        width={250} 
        height={250} 
      />

<Skeleton 
        className="skelton"

        count={1} 
        containerClassName="flex-1" 
        width={250} 
        height={250} 
      />

<Skeleton 
        className="skelton"

        count={1} 
        containerClassName="flex-1" 
        width={250} 
        height={250} 
      />
    </div>
  )}
</>
  )
}

export default TopCart
