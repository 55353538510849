import React, { useEffect, useState } from 'react';
import './Terms.css';
const Terms = () => {
    const [sections, setSections] = useState([]);

    useEffect(() => {
        let sectionCount = 0;

        function addSection(title, content) {
            sectionCount++;
            return { title: `${sectionCount}. ${title}`, content };
        }

        const newSections = [
            addSection('Introduction', 'Welcome to SolutionPUB. These terms and conditions outline the rules and regulations for the use of the SolutionPUB Website, located at <a href="https://solutionpub.dz">solutionpub.dz</a>.'),
            addSection('Acceptance of Terms', 'By accessing this website, we assume you accept these terms and conditions. Do not continue to use SolutionPUB if you do not agree to all of the terms and conditions stated on this page.'),
            addSection('Cookies', 'The website uses cookies to help personalize your online experience. By accessing SolutionPUB, you agreed to use the required cookies.'),
            // Add more sections as needed
        ];

        setSections(newSections);
    }, []);

    return (
        <div className="terms">
            <h2>Terms and Conditions</h2>
            {sections.map((section, index) => (
                <div key={index}>
                    <h3 dangerouslySetInnerHTML={{ __html: section.title }}></h3>
                    <p dangerouslySetInnerHTML={{ __html: section.content }}></p>
                </div>
            ))}
        </div>
    );
};

export default Terms;
