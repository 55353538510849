import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
const Navbar = () => {
  // Toogle Menu
  const [MobileMenu, setMobileMenu] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <header className='header'>
        <div className='container d_flex'>
          <div className='catgrories d_flex'>
            <span className='fa-solid fa-border-all'></span>
            <h4>
            {t('navbar.categories')} <i className='fa fa-chevron-down'></i>
            </h4>
          </div>

          <div className='navlink'>
            <ul className={MobileMenu ? "nav-links-MobileMenu" : "link f_flex capitalize"} onClick={() => setMobileMenu(false)}>
              {/*<ul className='link f_flex uppercase {MobileMenu ? "nav-links-MobileMenu" : "nav-links"} onClick={() => setMobileMenu(false)}'>*/}
              <li>
                <Link to='/'>
                  {t('navbar.home')}
                </Link>
              </li>
              <li>
                <Link to='/shop'>
                  {t('navbar.products')}
                </Link>
              </li>
              <li>
                <Link to='/app'>
                  {t('navbar.customization')}
                </Link>
              </li>
              <li>
                <Link to='/forum'>
                  {t('navbar.forum')}
                </Link>
              </li>
              <li>
                <Link to='/blog'>
                  {t('navbar.blog')}
                </Link>
              </li>

              <li>
                <Link to='/contact'>
                  {t('navbar.contact')}
                </Link>
              </li>
            </ul>

            <button className='toggle' onClick={() => setMobileMenu(!MobileMenu)}>
              {MobileMenu ? <i className='fas fa-times close home-btn'></i> : <i className='fas fa-bars open'></i>}
            </button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Navbar
