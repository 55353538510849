import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import ReactPaginate from 'react-paginate';
import { products } from "../../api/products";
import { items } from "../../api/items";
import ShopCart from "./ShopCart";

import { useTranslation } from "react-i18next";
import "./style.css";

const Shop = ({ addToCart }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [q, setQ] = useState(searchParams.get("q") || "");
  const [cat, setCat] = useState(searchParams.get("cat") || "all");
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
const q2 = searchParams.get("q") || "";
  const cat2 = searchParams.get("cat") || "all";
  const page2 = Number(searchParams.get("page")) || 1;
  const handlePageChange = (selectedPage) => {
    const nextPage = selectedPage.selected + 1;
    setPage(nextPage);
    navigate(`?q=${q}&cat=${cat}&page=${nextPage}`);
  };

  useEffect(() => {
    console.log("Fetching products with query:", q, cat, page);
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await items(q2, cat2, page2);
        if (result && result.data) {
          setData(result.data);
          
          const total = result.total;
          setTotalPages(Math.ceil(total / 15)); // Assuming 15 items per page
        }
      } catch (error) {
        console.error("Failed to fetch product details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [q2, cat2, page2]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page2]);

  if (loading) {
    return (
      <div className="product-wrapper">
        <div className="container">
          <div className="product-images-wrapper">
            <div className="preview-image-wrapper">
              <Skeleton height={500} />
            </div>
          </div>
          <div className="product-details-wrapper">
            <p className="product-brand">
              <Skeleton width={100} />
            </p>
            <h1 className="product-title">
              <Skeleton width={200} />
            </h1>
            <p className="product-description">
              <Skeleton count={5} />
            </p>
            <div className="product-price">
              <div className="current-price-wrapper">
                <h2 className="current-price">
                  <Skeleton width={100} />
                </h2>
                <span className="discount">
                  <Skeleton width={50} />
                </span>
              </div>
              <div className="previous-price-wrapper">
                <span className="previous-price">
                  <Skeleton width={50} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  let headingText = t("shop.all_products");
  if (cat !== "all") {
    headingText = t("shop.category");
  } else if (q) {
    headingText = t("shop.result");
  }

  return (
    <>
      <Helmet>
        <title>{headingText}</title>
        <meta name="description" content="Shop page" />
        <meta property="og:title" content="Shop" />
        <meta property="og:description" content="Shop page" />
        <meta name="keywords" content="shop, products, services" />
      </Helmet>
      <section className="shop background">
        <div className="container d_flex">
          <div className="contentWidth">
            <div className="heading d_flex">
              <div className="heading-left row f_flex">
                <h2>{headingText}</h2>
              </div>
              <div className="heading-right row">
                <span>
                  {t("view_all")}
                </span>
                <i className="fa-solid fa-caret-right"></i>
              </div>
            </div>
            <div className="product-content grid1">
              <ShopCart addToCart={addToCart} shopItems={data} />
            </div>
          </div>
        </div>
        <div className="pagination-wrapper">
          {totalPages > 1 && (
            <ReactPaginate
              pageCount={totalPages}
              pageRangeDisplayed={5}
              forcePage={page - 1} // Set the active page
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"pagination-active"}
              pageClassName={"pagination-page"}
              previousLabel={<i className="fa-solid fa-chevron-left"></i>}
              // set next <i> tag font awesome icon
              nextLabel={<i className="fa-solid fa-chevron-right"></i>}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Shop;
