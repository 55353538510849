import axios from "axios"

export const logout = async (authToken) => {
  const response = await axios.post("https://api.solutionpub.dz/api/logout", {
    authToken
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error(response)
  }
}

