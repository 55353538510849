import React from 'react';

function Main() {
    return (
        <div>
            <h1>Welcome to my blog!</h1>
            <p>This is the main component of the blog.</p>
        </div>
    );
}

export default Main;