import React, { useState, useEffect } from "react";
import axios from "axios";
import { product } from "../../api/product";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Similar from "./Similar";
import { Helmet } from "react-helmet";
import "./style.css";

const Product = ({ addToCart }) => {
  const productId = useParams().id;
  const [qt, setQt] = useState(0);

  const increment = () => {
    setQt(qt + 1);
  };

  const decrement = () => {
    if (qt > 0) {
      setQt(qt - 1);
    }
  };

  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(true);
  const [similarProducts, setSimilarProducts] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const response = await product(productId);
        setProductData(response.product);
        setSimilarProducts(response.similarProducts);
      } catch (error) {
        console.error("Failed to fetch product details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [productId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="product-wrapper">
        <div className="container">
          <div className="product-images-wrapper">
            <div className="preview-image-wrapper">
              <Skeleton height={500} />
            </div>
          </div>
          <div className="product-details-wrapper">
            <p className="product-brand">
              <Skeleton width={100} />
            </p>
            <h1 className="product-title">
              <Skeleton width={200} />
            </h1>
            <p className="product-description">
              <Skeleton count={5} />
            </p>
            <div className="product-price">
              <div className="current-price-wrapper">
                <h2 className="current-price">
                  <Skeleton width={100} />
                </h2>
                <span className="discount">
                  <Skeleton width={50} />
                </span>
              </div>
              <div className="previous-price-wrapper">
                <span className="previous-price">
                  <Skeleton width={50} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{productData.name}</title>
        <meta name="description" content={productData.description} />
        <meta property="og:title" content={productData.name} />
        <meta property="og:description" content={productData.description} />
        <meta property="og:image" content={`https://api.solutionpub.dz/public/products/${productData.picture}`} />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={productData.name} />
        <meta name="twitter:description" content={productData.description} />
        <meta name="twitter:image" content={`https://api.solutionpub.dz/public/products/${productData.picture}`} />
      </Helmet>
      <main className="main">
        <section className="product-wrapper">
          <div className="container">
            <div className="product-images-wrapper">
              <div className="preview-image-wrapper">
                <img
                  src={`https://api.solutionpub.dz/public/products/${productData.picture}`}
                  className="preview-image"
                  alt="Product Image"
                />
                <div className="arrows hide-for-desktop">
                  <div className="next">
                    <img src="./images/icon-next.svg" alt="Next Icon" />
                  </div>
                  <div className="prev">
                    <img src="./images/icon-previous.svg" alt="Previous Icon" />
                  </div>
                </div>
                <div className="count">
                  <p>
                    <span className="current"></span> of
                    <span className="total"></span>
                  </p>
                </div>
              </div>
            </div>
            <div className="product-details-wrapper">
              <p className="product-brand">{productData.category_name}</p>
              <h1 className="product-title">{productData.name}</h1>
              <p className="product-description">{productData.description}</p>

              {productData.promo > 0 ? (
                <div className="product-price">
                  <div className="current-price-wrapper">
                    <h2 className="current-price">
                      {(productData.price - (productData.price * productData.promo) / 100).toFixed(2)} DA
                    </h2>
                    <span className="discount">{productData.promo}% Off</span>
                  </div>
                  <div className="previous-price-wrapper">
                    <span className="previous-price">{productData.price} DA</span>
                  </div>
                </div>
              ) : (
                <div className="product-price">
                  <h2 className="current-price">{productData.price} DA</h2>
                </div>
              )}

              <div className="add-to-cart-form">
                <div className="product-quantity">
                  <button 
                  onClick={decrement}
                  type="button" className="button minus">
                    <i className="fa fa-minus"></i>
                  </button>
                  <span className="product-quantity-num">
                    {qt}
                  </span>
                  <button 
                                    onClick={increment}

                  type="button" className="button plus">
                    <i className="fa fa-plus"></i>
                  </button>
                </div>

                <button
                  aria-label="Add to cart"
                  onClick={() => addToCart(productData, qt)}
                  className="button add-btn"
                >
                  <img src="./images/icon-cart.svg" alt="" />
                  Add to cart
                </button>

                <p className="form-alert"></p>
              </div>
            </div>
          </div>
        </section>
        <Similar productItems={similarProducts} addToCart={addToCart} />
      </main>
    </>
  );
};

export default Product;
