import React from "react"
import Sdata from "./Sdata"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useTranslation } from "react-i18next"


const SlideCard = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: (dots) => {
      return <ul style={{ margin: "0px" }}>{dots}</ul>
    },
  }
  return (
    <>
    <div className="cc">
      <Slider {...settings}>
        {Sdata.map((value, index) => {
          return (
            <>
              <div className='box d_flex top' key={index}>
                <div className='left'>
                  <h1>
                    {lang === 'en' ? value.title : lang === 'fr' ? value.title_fr : value.title_ar}
                    </h1>
                  <p>
                    {lang === 'en' ? value.desc : lang === 'fr' ? value.desc_fr : value.desc_ar}
                  </p>
                  <button className='btn-primary'>
                  {lang === 'en' ? value.btn : lang === 'fr' ? value.btn_fr : value.btn_ar}
                  </button>
                </div>
                <div className='slide-img-container right'>
                  <img 
                  className='slide-img'
                  src={value.cover} alt='' />
                </div>
              </div>
            </>
          )
        })}
      </Slider>
      </div>
    </>
  )
}

export default SlideCard
