import React from "react"
import Home from "../components/MainPage/Home"
import Articles from "../components/annocument/Articles"
import FlashDeals from "../components/flashDeals/FlashDeals"
import Customize from "../components/customize/Customize"
import TopCate from "../components/top/TopCate"
import NewArrivals from "../components/newarrivals/NewArrivals"
import Discount from "../components/discount/Discount"
import Shop from "../components/shops/Shop"
import Annocument from "../components/annocument/Annocument"
import Wrapper from "../components/wrapper/Wrapper"
import { Helmet } from "react-helmet"
const Pages = ({ productItems, addToCart, CartItem, shopItems }) => {
  return (
    <>
      <Helmet>
        <title>SolutionPUB - Agency for advertising and marketing</title>
        <meta name="description" content="SolutionPUB - Agency for advertising and marketing" />
        <meta property="og:title" content="SolutionPUB - Agency for advertising and marketing" />
        <meta property="og:description" content="SolutionPUB - Agency for advertising and marketing" />
      </Helmet>


      <Home CartItem={CartItem} />

      <TopCate />
      <FlashDeals productItems={productItems} addToCart={addToCart} />
      <Customize productItems={productItems} addToCart={addToCart} />
      <Wrapper />

      <Articles />

      <Annocument />
    </>
  )
}

export default Pages
