import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './locales/en.json';
import fr from './locales/fr.json';
import ar from './locales/ar.json';

const resources = {
    en: {
        translation: en
    },
    fr: {
        translation: fr
    },
    ar: {
        translation: ar
    }
};

const storedLanguage = localStorage.getItem("lang");
const defaultLanguage = storedLanguage || "en"; // Set a default language if no language is stored

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: defaultLanguage,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
