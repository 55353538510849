import React, { useState } from "react";
import VerificationInput from "react-verification-input";

import { logout } from "../api/logout"
import { complete } from "../api/complete";

import { toast } from 'react-toastify';
// use history
import { useParams,  useNavigate } from "react-router-dom"

import "./login.css";

const Form = () => {

  const { token } = useParams()
  const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const Login = async (e) => {
        setLoading(true)
        try {
          const result = await complete(token, username, password)
            
          if (result && result.success) {

            toast.success('Welcome');
            window.location.href = "/"
          }
        }
        catch (error) {
          console.error("Login failed:", error)
          toast.error('Login failed!', { position: "bottom-center" });
        } finally {
          setLoading(false)
        }

        
    }
  const [value, setValue] = useState("");
    const history = useNavigate()
    const [logoutLoading, setLogoutLoading] = useState(false);


  const handleSubmit = async () => {
    console.log(value);
  }


    const signout = async () => {
      if (logoutLoading) return; // prevent click if logout is loading
      try {
        setLogoutLoading(true)
        const result = await logout(sessionStorage.getItem("authToken"))
  
        if (result) {
          // remove authtoken from session
          sessionStorage.removeItem("authToken")
          sessionStorage.removeItem("username")
          sessionStorage.removeItem("avatar")
          sessionStorage.removeItem("notconfirmed")
          toast.success('Logout successful!', { position: "bottom-center" });
          window.location.href = "/"
        }
        // Perform any additional actions after successful logout
      } catch (error) {
        // Handle any errors that occur during logout
        console.error("Logout failed:", error)
        toast.error('Logout failed!', { position: "bottom-center" });
      } finally {
        setLogoutLoading(false)
      }
    }

  return (
    <div 

    id = "confirm-container">
            <div id='logout-confirm'>
          <i
                  style={{
                    cursor: logoutLoading ? "not-allowed" : "pointer",
                  }}
                  onClick={signout}
                  className='fa fa-sign-out'
                ></i>
          </div>


             <div className='logo-login'>
            <img src={"./images/logo.svg"} alt='' />
          </div>


      
        <div
            className="container3"

        id = "confirm">
        <h2 className="title">
            
            Complete your registration
        </h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input 
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              type="text" placeholder="Username" />
            </div>
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input 
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              type="password" placeholder="Password" />
            </div>
            <input 
            disabled={loading}
            // add classname if loading
            style={{ cursor: loading ? "not-allowed" : "pointer" }}
            type="submit" onClick={(e) => Login(e)}
             value={loading ? "Loading..." : "Complete"}
             
             className="btn solid" />


</div>
</div>
  );
};

export default Form;
