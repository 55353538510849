import React from "react"
import "./style.css"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { newArticles } from "../../api/newArticles"


const Articles = () => {
  const { t, i18n } = useTranslation()
  const [data, setData] = useState(null)

  const lang = i18n.language

  useEffect(() => {
    const fetchData = async () => {
      const result = await newArticles()
      if (result && result.data) {
        setData(result.data)
      }
    }
    fetchData()
  }
  , [])

  if (!data) {
    return (
      <>
        <section className='Articles  background'>
                <div className='cc'>
                    <div className='heading f_flex'>
                      <i className='fa fa-newspaper'></i>
                      <h1>
                        {t("home.articles")}
                        </h1>
                    </div>
                <div class="container3">
                  <div className="article">
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/227/4-new.jpg" alt=""/>
            </div>
            <div className="article">
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/227/2.jpg" alt=""/>  
            </div>
            <div className="article">
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/227/3.jpg" alt=""/>  
            </div>
            <div className="article">
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/227/4-new.jpg" alt=""/>  
            </div>
            <div className="article">
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/227/5.jpg" alt=""/>  
            </div>
          </div>
          </div>
  
        </section>
      </>
    )
  }

  return (
    <>
      <section className='Articles  background'>
        <div className='cc'>
          <div className='heading f_flex'>
            <i className='fa fa-newspaper'></i>
            <h1>
              {t("home.articles")}
            </h1>
          </div>
          <div class="container3">
            {data.map((article) => (
              <div className="article">
                <img src={article.picture} alt=""/>
                <div className="article-content">
                  <h2>
                    {lang === "en" ? article.title : lang === "fr" ? article.title_fr : article.title_ar}
                  </h2>
                  <p>
                    {lang === "en" ? article.description : lang === "fr" ? article.description_fr : article.description_ar}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Articles
