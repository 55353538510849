import React, { useState } from "react"
import "./login.css"
import { Link } from "react-router-dom"
import { login } from "../api/login"
import { register } from "../api/register"
import { google } from "../api/google"
// redirect 
import { toast } from 'react-toastify';

import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
const Login = () => {
  const { t } = useTranslation()
  const [isSignUpMode, setIsSignUpMode] = useState(false)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSignUpClick = () => {
    setIsSignUpMode(true)
    setUsername("")
    setPassword("")
    setEmail("")

  }

  const handleSignInClick = () => {
    setIsSignUpMode(false)
    setUsername("")
    setPassword("")
    setEmail("")
  }

  const Login = async (e) => {
    setLoading(true)
    try {
      const result = await login(username, password)
      console.log(result)

      if (result && result.success) {

        // put authtoken in session : authoken
        sessionStorage.setItem("authToken", result.authToken)
        sessionStorage.setItem("username", result.username)
        if (result.avatar){
        sessionStorage.setItem("avatar", result.avatar)
        }

       
        if (result.notConfirmed) {
          sessionStorage.setItem("notconfirmed", "true")
          toast.success('Please confirm your email', { position: "bottom-center" });
          window.location.href = "/confirm"
          return
        } else {
          toast.success('Welcome back ' + result.username + '!', { position: "bottom-center" });
          window.location.href = "/"
        }
        




        // redirect to home page with react router



      }
    
    } catch (error) {
      // if 401
      if (error.response.status === 401) {
        toast.error('Invalid credentials', { position: "bottom-center" });
      }
    } finally {
      setLoading(false)
    }


  }


  const oauthGoogle = async () => {
    setLoading(true)
    // redirect to api.solutionpub.dz/auth/google
    window.location.href = "https://api.solutionpub.dz/auth/google"
  }


  const oauthFacebook = async () => {
    setLoading(true)
    // redirect to api.solutionpub.dz/auth/facebook
    window.location.href = "https://api.solutionpub.dz/auth/facebook"
  }



  const Register = async (e) => {
    setLoading(true)
    try {
      const result = await register(username, password, email)
      console.log(result)

      if (result && result.success) {

        // put authtoken in session : authoken
        sessionStorage.setItem("authToken", result.token)
        sessionStorage.setItem("username", result.username)
        sessionStorage.setItem("avatar", result.avatar)


        sessionStorage.setItem("notconfirmed", "true")
        toast.success('Please confirm your email', { position: "bottom-center" });
        window.location.href = "/confirm"

      }
    
    } catch (error) {
      // if 401
      if (error.response.status === 401) {
        alert("Invalid credentials")
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
    <Helmet>
      <title>Login to SolutionPUB - SolutionPub</title>
      <meta
        name='description'
        content='Login to SolutionPUB to access your account'
      />
      <meta property='og:title' content='Login to SolutionPUB - SolutionPub' />
      <meta
        property='og:description'
        content='Login to SolutionPUB to access your account'
      />
      <meta property='og:image' content='/images/logo.svg' />
      <meta property='og:url' content='https://solutionpub.dz/login' />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='SolutionPub' />
    </Helmet>
    <div className={`container2 ${isSignUpMode ? "sign-up-mode" : ""}`} id="container">
          <div className='logo-login'>
            <img src={"./images/logo.svg"} alt='' />
          </div>
      <div className="forms-container">
        <div className="signin-signup">
          <form action="#" className="sign-in-form">
            <h2 className="title">

              {t("login.signin")}
            </h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input 
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              type="text" placeholder={t("login.username")} />
            </div>
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input 
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              type="password" placeholder={t("login.password")} />
            </div>
            <input 
            disabled={loading}
            // add classname if loading
            style={{ cursor: loading ? "not-allowed" : "pointer" }}
            type="submit" onClick={(e) => Login(e)}
             value={loading ? t('loading') : t('login.signin')}
             
             className="btn solid" />
            <p className="social-text">
              {t("login.or_sign_in")}
            </p>
            <div className="social-media">
              <a 
              onClick={oauthFacebook}
              
              className="social-icon">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a 
              onClick={oauthGoogle}
              className="social-icon">
                <i className="fab fa-google"></i>
              </a>
            </div>
          </form>
          <form action="#" className="sign-up-form">
            <h2 className="title">
              {t("login.signup")}
            </h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input 
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              type="text" placeholder={t("login.username")} />
            </div>
            <div className="input-field">
              <i className="fas fa-envelope"></i>
              <input 
              
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              type="email" placeholder={t("login.email")} />
            </div>
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input 
              
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              type="password" placeholder={t("login.password")} />
            </div>
            <input 
              disabled={loading}
              style={{ cursor: loading ? "not-allowed" : "pointer" }}
              onClick={(e) => Register(e)}
            type="submit" className="btn" value={loading ? t('loading') : t('login.signup')} />
            <p className="social-text">
              {t("login.or_sign_in")}
            </p>
            <div className="social-media">
              <a href="#" className="social-icon">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="social-icon">
                <i className="fab fa-google"></i>
              </a>

            </div>
          </form>
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>
              {t('login.new_here')}
            </h3>
            <p>
            {t('login.intro')}

            </p>
            <button 
              onClick={handleSignUpClick}
              className="btn transparent" 
              id="sign-up-btn">
              {t('login.signup')}
            </button>
          </div>
          <img src="img/log.svg" className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>
            {t('login.one_of_us')}

            </h3>
            <p>
            {t('login.connect')}
            </p>
            <button 
              onClick={handleSignInClick}
              className="btn transparent" 
              id="sign-in-btn">
              {t('login.signin')}
            </button>
          </div>
          <img src="img/register.svg" className="image" alt="" />
        </div>
      </div>
    </div>
    </>

  )
}

export default Login
